import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import SEO from '../../components/SEO'
import FullWidthWrapper from '../../components/FullWidthWrapper'
import ParallaxWrapper from '../../components/ParallaxWrapper'
import Overlay from '../../components/Overlay'
import WordArt from '../../components/WordArt'
import ContentBlock from '../../components/ContentBlock'
import AngledDivider from '../../components/AngledDivider'
import BigHeading from '../../components/BigHeading'
import Paragraph from '../../components/Paragraph'
import FlexBox from '../../components/FlexBox'
import ActionLink from '../../components/ActionLink'
import ContactSection from '../../components/ContactSection'

import ArrowWhite from '../../graphics/ne-arrow-white.svg'
import ArrowOrange from '../../graphics/ne-arrow.svg'
import BookIcon from '../../graphics/book.svg'
import DigitalIcon from '../../graphics/digital.svg'
import VideoIcon from '../../graphics/video.svg'
import AppIcon from '../../graphics/app.svg'
import WebIcon from '../../graphics/web.svg'
import VisualIcon from '../../graphics/visual.svg'
import BeaconIcon from '../../graphics/beacon.svg'

const services = [
  {
    name: 'Brand Book Publishing',
    description:
      'It’s important that a company stays on brand. Our “Brand Book” includes messaging, tone, graphics, and copy elements that distill your unique value proposition into an all-encompassing guide for communicating your brand’s essence.',
    anchor: 'brand-book-publishing',
    icon: BookIcon
  },
  {
    name: 'Digital Marketing',
    description:
      'It all starts with market analysis and audience segmentation. Using focus groups and extensive research, we tap into the culture of your consumers and understand the people who love your product (even if they don’t know it yet). Then we drive traffic back to your business with messaging intended for buyers already looking for the product or service you provide.',
    anchor: 'digital-marketing',
    icon: DigitalIcon
  },
  {
    name: 'Video Production',
    description:
      'Looking to make a video advertisement for TV, web, or social media? You’ve come to the right place. Storyboarding, casting, location scouting, filming, soundscaping, color grading... we could go on, but you get the picture. This is the whole shebang—every step of the process is meticulously controlled to capture your brand’s unique style one frame at a time.',
    anchor: 'video-production',
    icon: VideoIcon,
    links: [{ path: '/services/video/library', label: 'Video Library' }]
  },
  {
    name: 'Web Design & Development',
    description:
      'Functional websites lead to more sales—period. Your website needs to be more than just a billboard. At the end of the sales funnel, consumers will find themselves on your site looking to complete a purchase.',
    anchor: 'website-design-development',
    icon: WebIcon
  },
  {
    name: 'App Design & Development',
    description:
      'It’s a mobile world and brands are finding new ways to share their products and services. From streaming music to buying stocks, we are conditioned to “download the app.” You bring the ideas and we’ll get you on the app store.',
    anchor: 'app-design-development',
    icon: AppIcon
  },
  {
    name: 'Visual Marketing',
    description:
      'It’s nearly impossible to build a website, run digital ads, or traditionally market without a designated team of artists and designers. Fortunately, we’re design specialists who understand the best practices when mixing media to publish messaging that resonates with your brand.',
    anchor: 'visual-marketing',
    icon: VisualIcon
  },
  {
    name: 'Beacon Advertising',
    description:
      'Beacon advertising has become a popular space for direct-to-consumer marketing. For businesses who regularly host events, beacons can collect valuable data, such as which vendor achieved the most foot traffic throughout the entirety of an event. For vendors, beacons open up a world of marketing experiences: offer localized deals and tailor interactive suggestions based on customer behavior.',
    anchor: 'beacon-advertising',
    icon: BeaconIcon
  }
]

const Services = ({ data }) => {
  return (
    <React.Fragment>
      <SEO title="Services" />
      <Hero image={data.heroImage} />
      <AngledDivider color="#1b1b1b" position="top" />
      <ContentBlock
        backgroundColor="#1b1b1b"
        css={`
          padding-bottom: 20vh;
        `}
      >
        <FlexBox flexDirection="row">
          <div
            css={`
              @media (max-width: 768px) {
                display: none;
              }
              width: 30vw;
            `}
          />
          <FlexBox flexDirection="column">
            {services.map((service, index) => (
              <Service key={index} {...service} />
            ))}
          </FlexBox>
        </FlexBox>
      </ContentBlock>
      <AngledDivider color="#f93616" position="top" />
      <ContentBlock backgroundColor="#f93616">
        <FlexBox flexDirection="row" itemSpacing="4rem" isResponsive>
          <BigHeading
            css={`
              max-width: 40rem;
            `}
          >
            Feeling ambitious?
            <br />
            So are we.
          </BigHeading>
          <FlexBox
            flexDirection="column"
            alignItems="flex-start"
            itemSpacing="4rem"
          >
            <Paragraph>
              Launching brands is our specialty. We fuse strategy, platform, and
              communication to accelerate your ideas towards tangible success.
            </Paragraph>
            <ActionLink
              to="/branding"
              css={`
                color: #fff;
              `}
            >
              Let's Launch
              <ArrowWhite
                css={`
                  margin-left: 1rem;
                `}
              />
            </ActionLink>
          </FlexBox>
        </FlexBox>
      </ContentBlock>
      <AngledDivider color="#f93616" position="bottom" />
      <ContactSection />
    </React.Fragment>
  )
}

const Hero = ({ image }) => (
  <FullWidthWrapper
    height="110vh"
    css={`
      background-color: #1b1b1b;
    `}
  >
    <Image
      fluid={image.childImageSharp.fluid}
      css={`
        height: 100%;
      `}
      alt=""
    />
    <Overlay alpha="0.3" />
    <ParallaxWrapper
      css={`
        top: 0;
      `}
    >
      <HeroHeading />
    </ParallaxWrapper>
  </FullWidthWrapper>
)

const HeroHeading = () => (
  <WordArt.Wrapper>
    <WordArt.Line color="#f93616" fontSize="1.6em">
      Build
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="1.6em">
      Your
    </WordArt.Line>
    <WordArt.Line color="#f93616" fontSize="1.3em">
      Brand
    </WordArt.Line>
  </WordArt.Wrapper>
)

const Service = ({ name, description, anchor, icon: IconComponent, links }) => (
  <FlexBox
    flexDirection="column"
    alignItems="flex-start"
    itemSpacing="2rem"
    id={anchor}
    css={`
      padding-top: 4vmax;
    `}
  >
    <FlexBox flexDirection="row" alignItems="center" itemSpacing="2rem">
      <IconComponent
        style={{
          width: '50px',
          height: '50px'
        }}
      />
      <BigHeading>{name}</BigHeading>
    </FlexBox>
    <Paragraph>{description}</Paragraph>
    {links &&
      links.map(link => (
        <ActionLink to={link.path}>
          {link.label}
          <ArrowOrange
            css={`
              margin-left: 1rem;
            `}
          />
        </ActionLink>
      ))}
  </FlexBox>
)

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "moon.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Services
